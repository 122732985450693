<script>
  import { link } from "svelte-routing";

  // core components
  import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
  import UserDropdown from "components/Dropdowns/UserDropdown.svelte";
  import { t, locale, locales } from "../../i18n";

  const sWindowsDownloadURL = "../../resource/NeosNetClient_Win_Install_Ver3_100.zip"
  const sAndroidDownloadURL = "../../resource/NeosNet.VIS.SG-v3.1.2-beta-260030102-release.apk"

  //export const location;

  // import ChooseNode from "views/admin/ChooseNode.svelte";
  $: time = new Date().toISOString().
  replace(/T/, ' ').      // replace T with a space
  replace(/\..+/, '').substring(0,10)     // delete the dot and everything after

  let collapseShow = "hidden";

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }

  
</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >

 <p class="text-lg  py-3 font-bold block">{@html  $t("homepage.time", { time })}</p>
 
  
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->
    <a
      use:link
      class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm  font-bold p-4 px-0"
      href="/"
    >
    {@html  $t("homepage.product", { time })}
    </a>

    <!-- User -->
    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <NotificationDropdown />
      </li>
      <li class="inline-block relative">
        <UserDropdown />
      </li>
    </ul>

    <h2><a href="{sWindowsDownloadURL}" class="text-red-500">{@html  $t("neosnet4windows")}</a></h2>
    <h2><a href="{sAndroidDownloadURL}" class="text-red-500">{@html  $t("neosnet4android")}</a></h2>

    <!--ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
      <li class="items-center">
        <a
          use:link
          class="text-blueGray-700 hover:text-blueGray-500 text-xs  py-3 font-bold uppercase block"
          href="https://www.vis.sg"
        >
          <i class="fas fa-fingerprint text-blueGray-300 mr-2 text-sm"></i>
          {@html  $t("homepage.desktop")}
        </a>
      </li>

      <li class="items-center">
        <a
          use:link
          class="text-blueGray-700 hover:text-blueGray-500 text-xs  py-3 font-bold uppercase block"
          href="https://www.vis.sg/mobile"
        >
          <i class="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>
          {@html  $t("homepage.mobile")}
        </a>
      </li>
    </ul-->
    


  <!-- Divider -->
  <hr class="my-4 md:min-w-full" />
  <!-- Heading -->
  <h6
    class="md:min-w-full text-blueGray-500 text-xs  font-bold uppercase block pt-1 pb-4 no-underline"
  >
   {@html  $t("homepage.accountManagement")}
  </h6>
  <!-- Navigation -->
    
    <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
      <li class="items-center">
        <a
          use:link
          class="text-blueGray-700 hover:text-blueGray-500 text-xs  py-3 font-bold uppercase block"
          href="/admin/login"
        >
          <i class="fas fa-fingerprint text-blueGray-300 mr-2 text-sm"></i>
          {@html  $t("homepage.login")}
        </a>
      </li>

      <li class="items-center">
        <a
          use:link
          class="text-blueGray-700 hover:text-blueGray-500 text-xs  py-3 font-bold uppercase block"
          href="/admin/register"
        >
          <i class="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>
          {@html  $t("homepage.register")}
        </a>
      </li>
      <li class="items-center">
        <a
          use:link
          class="text-blueGray-700 hover:text-blueGray-500 text-xs  py-3 font-bold uppercase block"
          href="/admin/changepassword"
        >
          <i class="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>
          {@html  $t("ChangePassword")}
        </a>
      </li>

      <!--li class="items-center">
        <a
          use:link
          class="text-blueGray-700 hover:text-blueGray-500 text-xs  py-3 font-bold uppercase block"
          href="/admin/payment"
        >
          <i class="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>
          {@html  $t("homepage.payment")}
        </a>
      </li-->
    </ul>
    
    


    <!-- Collapse -->
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              use:link
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm  font-bold p-4 px-0"
              href="/"
            >
            {@html  $t("homepage.product")}
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow('hidden')}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Form -->
      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input
            type="text"
            placeholder="Search"
            class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
          />
        </div>
      </form>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs  font-bold block pt-1 pb-4 no-underline"
      >
      {@html  $t("homepage.operations")}
      </h6>
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <a
            use:link
            href="/admin/introduction"
            class="text-xs  py-3 font-bold uppercase block {location.href.indexOf('/admin/introduction') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {location.href.indexOf('/admin/introduction') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            {@html  $t("homepage.introduction")}
          </a>
        </li>

        <li class="items-center">
          <a
            use:link
            href="/admin/download"
            class="text-xs  py-3 font-bold  uppercase block {location.href.indexOf('/admin/settings') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm {location.href.indexOf('/admin/settings') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            {@html  $t("homepage.download")}
          </a>
        </li>

        <li class="items-center">
          <a
            use:link
            href="/admin/commonQA"
            class="text-xs  py-3 font-bold uppercase block {location.href.indexOf('/admin/baseline') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-server mr-2 text-sm {location.href.indexOf('/admin/baseline') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            {@html  $t("homepage.commonQA")}
          </a>
        </li>
        <li class="items-center">
          <a
            use:link
            href="/admin/contact"
            class="text-xs  py-3 font-bold uppercase block {location.href.indexOf('/admin/memory') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-server mr-2 text-sm {location.href.indexOf('/admin/memory') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            {@html  $t("homepage.contact")}
          </a>
        </li>

      </ul>
      <select bind:value={$locale} class="border-none"  size="2">
        <option value="en"  class="text-xs  py-3 font-bold uppercase block border-none" on:click={() => toggleCollapseShow('hidden')}>English   </option>
        <option value="cn"  class="text-xs  py-3 font-bold uppercase block border-none" on:click={() => toggleCollapseShow('hidden')}>简体中文   </option>
      </select>
    

    </div>
  </div>

  
</nav>


